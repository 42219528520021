<template>
  <div class="purchase-method-detail-list">
    <div class="detail-item" v-for="item in dataList" :key="item.id">
      <detail-item @dataUpdate="$emit('dataUpdate')" :statusMap="statusMap" :config="config" :data="item"></detail-item>
    </div>
  </div>
</template>

<script>
import detailItem from './detailItem'

export default {
  components: {
    detailItem
  },
  props: {
    dataList: { type: Array, defualt: () => [] },
    config: { type: Array, defualt: () => [] },
    statusMap: { type: Object, defualt: () => {} }
  }
}
</script>

<style scoped lang="less">
.purchase-method-detail-list {
  padding: 20px;
  overflow-y: auto;
  .detail-item {
    margin-bottom: 20px;
  }
}
</style>